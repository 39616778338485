import "./main.css"
import {Link} from "react-router-dom"
import useScreenSize from "../../hooks/useScreenSize"
import { useEffect } from "react"
import EmblaCarousel from "../../components/carousel"
import AutoScroll from 'embla-carousel-auto-scroll'
import parties from "../../components/carousel/parties"
import carouselArtists from "../../components/carousel/artists"
const LandingPage = () => {
    const screenSize = useScreenSize();
    const OPTIONS = { dragFree: true, loop: true, slidesToScroll: 'auto' };
    return(
        <div className="lp-container" style={{width:screenSize.width}}>
        <h1>ABOUT US</h1><br/>
        <p>Los Bandoleros is a booking and impresario agency based in Romania.</p>
        <h2>OUR ARTISTS</h2>
        <p>We represent a dynamic roster of artists who are redefining the landscape of electronic music. Our artists are united by their passion and creativity.</p>
        <EmblaCarousel slides={carouselArtists} options={OPTIONS} link={true} />
        <h1 className="break">Break! Questions?</h1>
        <Link to="/contact"className="animate__animated animate__heartBeat animate__infinite animate__slower contact-us-span">Contact Us!</Link>
        <h2>OUR TEAM</h2>
        <p>Our team is made up of experienced artists and professional DJs with experience in the music industry and succesful events.</p>
        <EmblaCarousel slides={parties} options={OPTIONS} link={false} />
        <h2>WHY SHOULD YOU CHOOSE US?</h2>
        <p>We are not just another booking agency, we are a dynamic force of creativity, passion and dedication to music. This passion drives us to go above and beyond in everything we do, from supporting new talents to creating unforgettable experiences.</p>
        <h1 style={{fontSize:28}}>We convinced you? <br/><Link to="/contact" className="contact-us-span" style={{marginBottom:20,fontSize:28}}>Lets get in touch</Link></h1>
        <h2 style={{marginTop:60}}>SUPPORTING NEW TALENTS</h2>
        <p>We are an artist management agency dedicated to supporting emerging artists, providing them with the necessary resources and support to achieve their goals.<br/><br/>
        We have built strong networks and connections within the industry. By leveraging these relationships, we assist our clients in collaborating with other artists, producers, composers, and industry professionals.<br/><br/> We create opportunities for introductions and partnerships that can open doors to new prospects and foster career growth.<br/><br/></p>
        <EmblaCarousel slides={parties} options={OPTIONS} link={false} />
        <br/><br/>
        <h2 style={{paddingTop:40}}>EVENTS ORGANIZATION</h2>
        <p>Gain access to a network of professionals and exclusive opportunities.<br/><br/> Enhance your online presence with our digital marketing strategies.<br/><br/> Work with fellow agency artists to broaden your creative horizons.<br/><br/> Receive customized support aligned with your artistic goals.<br/><br/> We manage the event according to your vision and carefully handle every detail, ensuring that participants' reactions after the event are always "Wow!"</p>
        <Link to="/contact"className="animate__animated animate__heartBeat animate__infinite animate__slower contact-us-span last-contact">Now contact us!</Link>
        </div>
    )
}

export default LandingPage