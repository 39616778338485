import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import {Link} from "react-router-dom"
import "./main.css"

const EmblaCarousel = (props) => {
  const { slides, options,link } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({ speed: .5, startDelay: 0})
  ])
  return (
    !link ? <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((image) => (
            <img src={image.url} className="embla__slide" key={image.id}>
            </img>
          ))}
        </div>
      </div>
    </section> : <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((image) => (
            <Link to={`/artist/${image.id}`} className="embla__slide"><img src={image.url} key={image.id} className="embla__image">
            </img></Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export default EmblaCarousel