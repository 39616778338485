import Instagram from "./svg/instagram.svg"
import Email from "./svg/email.svg"
import Address from "./svg/address.svg"
import Phone from "./svg/phone.svg"
import "./main.css"
import useScreenSize from "../../hooks/useScreenSize"

export default function ContactBox(){
    return(
    <div className="contact-box" >
        <div className="contact-box-item contact-box-instagram"><img src={Instagram} width="20" height="20"/><a href="https://www.instagram.com/losbandoleros.ro/" target="_blank" className="contact-box-text">@losbandoleros.ro</a></div>
        <div className="contact-box-item contact-box-email"><img src={Email} width="20" height="20"/><a href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@losbandoleros.ro" target="_blank" className="contact-box-text">contact@losbandoleros.ro</a></div>
        <div className="contact-box-item contact-box-phone"><img src={Phone} width="20" height="20"/><a href="#" className="contact-box-text">+40 31 424 25 88</a></div>
        <div className="contact-box-item contact-box-address"><img src={Address} width="20" height="20"/><a href="https://maps.app.goo.gl/JL7MZr2u81MwrM7w5" target="_blank" className="contact-box-text">Constantin Radulescu Motru 13</a></div>
    </div>)
}