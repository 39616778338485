import emailjs from "@emailjs/browser"
import {useRef,useState} from "react"
import "./main.css"
import validator from "validator"
import ContactBox from "../../components/contact-box"
import useScreenSize from "../../hooks/useScreenSize"

const Contact = () => {
    const form = useRef();
    const [email,setEmail] = useState("")
    const [phone,setPhone] = useState("")
    const [name,setName] = useState("")
    const [message,setMessage] = useState("")
    const [error,setError] = useState("")
    const [alert,setAlert] = useState("")
    const screenSize = useScreenSize();

    const eraseBox = () => {
        console.log("erase started")
        setTimeout(() => {
            setError("");
            setAlert("");
        },5000)
    }

    if(error || alert){
        eraseBox();
    }


    const sendEmail = (e) => {
        e.preventDefault();
        try{
            if(!email || !name || !message || !phone){
                throw Error("Complete all inputs")
            }else if(!validator.isEmail(email)){
                throw Error("Email not valid!");
            }else if(!validator.isMobilePhone(phone)){
                throw Error("Phone number not valid!")
            }else{
                setError("");
                setAlert("Sending email...");
                emailjs.sendForm("service_erga2dh","template_0q1ovvf",form.current,{publicKey:"2BelVwtzfzQdsWa3q"})
                .then(() =>{
                    console.log("email sent!");
                    setAlert("Email sent!")
            })
            }
        }catch(err){
            setError(err);
        }
        
    }

    return(
        <div className="contact-screen animate__animated animate__fadeIn" style={{width:screenSize.width*60/100}}>
            {error && <div className="error-box animate__animated animate__fadeIn">{error.message}</div>}
            {alert && <div className="alert-box">{alert}</div>}
            <ContactBox />
            <form className="contact-container" ref={form} onSubmit={sendEmail} style={{width:screenSize.width*40/100}}>
                <div className="header">Get in touch!</div>
                <input className="contact-input input-name" name="from_name" type="text" placeholder="Full Name" onChange={(e) => setName(e.target.value)}/>
                <input className="contact-input input-phone" name="phone" type="text" placeholder="Your Number" onChange={(e) => setPhone(e.target.value)}/>
                <input className="contact-input input-email" name="my_email" type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                <textarea className="contact-input input-message" name="message" type="text" placeholder="Message" onChange={(e) => setMessage(e.target.value)}/>
                <input type="submit" value="SEND" className="contact-button"/>
            </form>
            <div className="margin-bottom"></div>
        </div>
    )
}

export default Contact