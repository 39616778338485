const parties = [
    {
        id:1,
        name:"Babylon Hollywood Villa",
        url:"https://res.cloudinary.com/dsy9com82/image/upload/v1718977299/parties/photo_6025895921981702288_y_fruoj4.jpg"
    },
    {
        id:2,
        name:"Babylon Hollywood Villa",
        url:"https://res.cloudinary.com/dsy9com82/image/upload/v1718977297/parties/photo_6025895921981702291_y_kffyyr.jpg"
    },
    {
        id:3,
        name:"Babylon Hollywood Villa",
        url:"https://res.cloudinary.com/dsy9com82/image/upload/v1718997127/parties/photo_6025895921981702298_y_ti87lz.jpg"
    },
    {
        id:4,
        name:"Babylon Hollywood Villa",
        url:"https://res.cloudinary.com/dsy9com82/image/upload/v1718997132/parties/photo_6025895921981702293_y_ofo8vx.jpg"
    },
    {
        id:5,
        url:"https://res.cloudinary.com/dsy9com82/image/upload/v1718997138/parties/photo_6025895921981702294_y_1_lh9xhh.jpg"
    }
]

module.exports = parties