import "./main.css"
import "animate.css"
import Email from "./svg/email.svg"
import Tiktok from "./svg/tiktok.svg"
import Instagram from "./svg/instagram.svg"
import useScreenSize from "../../hooks/useScreenSize"

const Info = () => {
    const screenSize = useScreenSize();
    return(
        <div className="info-screen animate__animated animate__fadeIn" style={{width:screenSize.width}}>
            <div className="info-page">
                <p className="text">
                WHO WE ARE<br/><br/>

Los Bandoleros is a booking and impresario agency based in Romania.Our team is made up of xperienced artists and professional DJs with experience in the music industry and succesful events.


<br/><br/><br/><br/>WHAT WE DO<br/><br/>

We are more than just an agency – we bring our artist in the right place,at the right time. We are here to listen and understand your wishes and your needs. We collaborate closely with our clients, building a genuine relationship so that every event will be a success. Besides the events we attend,we organise our own showcase parties to promote our artists and we also search young talents whom we help to reach their maximum potential.



<br/><br/><br/><br/>OUR HISTORY<br/><br/>

Los Bandoleros is a fresh project built by an experienced professional team with more than 7 years of experience in the domain.We bring a fresh breath of air in the industry by working with young and talented artists creating a modern and different atmosphere.
Our roots run deep in the heart of the electronic music , where innovation meets passion and creativity knows no bounds.


Join us as we continue to redefine the borders of electronic music and write the next chapter in the story of Los Bandoleros.
                </p>
                {/*<div className="contact-container">
                    <div className="contact-box">
                        <img src={Instagram} className="contact-logo"/>
                        <a href="https://www.instagram.com/losbandoleros.ro/" target="_blank" className="contact-text">@losbandoleros.ro</a>
                    </div>
                    <div className="contact-box">
                        <img src={Tiktok} className="contact-logo"/>
                        <a href="https://www.tiktok.com/@losbandoleros.ro" target="_blank" className="contact-text">@losbandoleros.ro</a>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}

export default Info